@font-face {
  font-family: "fontitalic";
  src: url("../public/editorial-new-font-family-1719954939-0/PPEditorialNew-Ultralight-BF644b21500d0c0.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "fontbold";
  src: url("../public/TestFoundersGrotesk-Medium.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "fontlight";
  src: url("../public/TestFoundersGrotesk-Light.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --main: #ff97dc;
  --primary: #fffb00;
  --white: #ffffff;
}
a {
  border: none !important;
  outline: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
}
*:focus,
*:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.bg-main {
  background-color: var(--main) !important;
}
.bg-primarys {
  background-color: var(--primary) !important;
}
.bg-whiter {
  background-color: var(--white) !important;
}
.text-main {
  color: var(--main) !important;
}
.text-primarys {
  color: var(--primary) !important;
}
.text-whiter {
  color: var(--white) !important;
}
.border-main {
  border: 2px solid var(--main) !important;
}
.border-primarys {
  border: 2px solid var(--primary) !important;
}
.border-whiter {
  border: 2px solid var(--white) !important;
}

.fontitalic {
  font-family: "fontitalic";
}
.fontbold {
  font-family: "fontbold";
}
.fontlight {
  font-family: "fontlight";
}

.filterr {
  backdrop-filter: blur(100px);
}

.for-mob-heads{
  padding-bottom: 0 !important;
}
.cus-mar{
  margin-bottom: 0;
}
.what-i-do{
  padding: 6rem 0 8rem 0;
}
.cus-heasd{
  margin-bottom: 4rem;
}
.email-me-s{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 2rem 0;
}
.email-me-s a{
  background-color: #ff97dc;
  color: #ffffff;
  font-family: "fontlight";
  font-size: 21px;
  padding: 10px 25px;
}
.email-me-s a:hover{
  background-color: #ff97dc;
  color: #ffffff;
}
.footer-icons{
  margin-bottom: 1.5rem;
  i{
    color: black;
  }
}
.hey-me{
  font-size: 3.5rem !important;
}
.hey-body{
  font-size: 1.5rem !important;
}

@media only screen and (max-width: 991px){
  .what-i-do{
    padding: 4.5rem 0 3rem 0;
  }
  .cus-font-mob p{
    font-size: 22px !important;
    line-height: 31px;
  }
  .cus-mob-pad{
    padding: 0 !important;
  }
  .cus-mob-pads{
    padding: 0 !important;
  }
  .sec-one-mob{
    min-height: 40vh !important;
  }
  .cus-cols{
    margin: 1rem 0;
  }
  .cus-cols-heads{
    padding: 0 !important;
  }
  .some-cus{
    padding-bottom: 1rem !important;
  }
  .cus-heasd{
    margin-bottom: 2rem;
  }
  .gif-cont-a{
    text-align: center;
  }
  .gif-cont-a img{
    transform: translate(0) !important;
    width: 70% !important;
  }
  .hey-me{
    font-size: 2.5rem !important;
    text-align: center;
  }
  .hey-body{
    font-size: 1.2rem !important;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
}